<template>
  <article class="page-contents-wrap">
    <sub-title :text="'게임별 보험베팅요율'">
      <div>
        <input type="number" list="biRateControl" class="mr-5" @change="totalRateLimit" v-model="controlRate" />
        <datalist id="ptRate">
          <template v-for="(n, index) in 200 + 1" :key="index">
            <option :value="(index * 0.5).toFixed(2)">
              {{ (index * 0.5).toFixed(2) }}
            </option>
           </template>
        </datalist>
        <button type="button" class="btn-layout btn-grey mr-5" title="새로고침" @click="refreshData">
          <i class="fas fa-redo"></i>
        </button>
        <button type="button" class="btn-layout btn-blue mr-5" @click="rateAllChange">일괄적용</button>
        <button type="button" class="btn-layout btn-blue" @click="setBiBetRates">{{ $t('button.save') }}</button>
      </div>
    </sub-title>
    <div class="page-content s-content-wrap wrapper-contents itemz">
      <template v-for="(item, index) in rateList" :key="item.gameType">
        <div class="s-content">
          <div class="title">
            <span>{{ item.vendorName }}</span>
          </div>
          <div class="content">
            <div class="rollcount">
              <button type="button" @click="decrease(index)">-</button>
              <input type="number" @change="rateLimit(index)" v-model="item.biSubPointRate" />
              <button type="button" @click="increase(index)">+</button>
            </div>
          </div>
        </div>
      </template>
    </div>
  </article>
</template>
<script>
import lodash from "lodash";
import subTitle from "@/components/main/PageSubTitle";
import { getSiteData } from "@/libs/auth-helper";
import { companyDetail  } from "@/api/member";
import { compGamesBiSubRateModify } from "@/api/company";
export default {
  name: "RateSetting",
  components: {
    subTitle
  },
  data() {
    return {
      memberDetail:{},
      rateList:[]
    };
  },
  methods: {
    async setBiBetRates() {
      console.log(this.memberDetail);
      const req = {
        siteId: this.memberDetail.memId,
        biRateList: []
      };
      for (const item of this.memberDetail.BI_SubPointRate) {
        const model = {
          gameType: item.gameType,
          vendorCode: item.vendorCode,
          biSubPointRate: item.biSubPointRate
        };
        req.biRateList.push(model);
      }
      const res = await compGamesBiSubRateModify(req);
      console.log(res);
      if (res.resultCode === "0") {
        alert("설정완료");
        await this.refreshData();
      } else {
        alert("설정실패");
      }
    },
    async refreshData() {
      try {
        await this.setGameList();
        this.setRateList();
      } catch (e) {
        console.error(e);
      } finally {
        // this.scontentSetting();
      }
    },
     biRateAllChange() {
      const target = this.$refs.biRateControl;
      let value = target.value;
      console.log(value);
      if (value > 100) {
        value = 100;
      } else if (value < 0) {
        value = 0;
      }
      const ptRates = this.memberDetail.BI_SubPointRate;
      for (const item of ptRates) {
        item.biSubPointRate = value;
      }
    },
    creditRoop() {},
    setRateList() {

    },
    totalRateLimit() {
      const n = Number(this.controlRate).toFixed(2);
      if (n >= 100) {
        this.controlRate = (100).toFixed(2);
      } else if (n < 0) {
        this.controlRate = (0).toFixed(2);
      } else {
        this.controlRate = Number(this.controlRate).toFixed(2);
      }
    },
    rateAllChange() {
      const rateList = this.rateList;
      for (const item of rateList) {
        item.biSubPointRate = this.controlRate;
      }
    },
    rateLimit(idx) {
      const n = Number(this.rateList[idx].biSubPointRate).toFixed(2);
      if (n >= 100) {
        this.rateList[idx].biSubPointRate = (100).toFixed(2);
      } else if (n < 0) {
        this.rateList[idx].biSubPointRate = (0).toFixed(2);
      } else {
        this.rateList[idx].biSubPointRate = Number(this.rateList[idx].biSubPointRate).toFixed(2);
      }
    },
    increase: function (idx) {
      if (Number(this.rateList[idx].biSubPointRate) === 100) {
        return;
      }
      this.rateList[idx].biSubPointRate = (Number(this.rateList[idx].biSubPointRate) + 0.05).toFixed(2);
    },
    decrease: function (idx) {
      if (Number(this.rateList[idx].biSubPointRate) <= 0) {
        return;
      }
      this.rateList[idx].biSubPointRate = (Number(this.rateList[idx].biSubPointRate) - 0.05).toFixed(2);
    },
    getSiteInfo() {
      const siteInfoCookie = getSiteData();
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie);
        return siteInfo;
      }
    },
    async getUpperCompany() {
      const reqDetail = {
        siteId: this.$route.params.id,
        memId: this.$route.params.id
      };
      const loginCompanyDetail = await companyDetail(reqDetail);

      return await loginCompanyDetail.data.memberDetail;
    }
  },
  async created() {
    this.memberDetail = await this.getUpperCompany();
    this.rateList = this.memberDetail.BI_SubPointRate;
    await this.setGameList();
    this.setRateList();
  }
};
</script>
<style scoped>
body{
  height:100vh;
}
.page-contents-wrap{
  margin-bottom:0;
  height:100%;
}
.s-content > .content {
  display: flex;
  font-size: 1em;
  height: calc(56px - 26px);
}

.s-content-wrap {
  max-height: calc(100% - 64px);
  overflow-y: auto;
}

.s-content > .title {
  margin-bottom: 0;
  background: #18698b;
  white-space: nowrap;
  color: #fff;
  border-radius: 6px 6px 0 0;
}

.itemz .s-content {
  max-width: unset;
  width: 135px;
  flex: 0 0 auto;
  flex-basis: unset;
  border: 0;
}

.itemz .s-content .content select {
  border-top: 0;
  border-radius: 0 0 6px 6px;
  height: 30px;
  text-align: center;
}

.rollcount {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #9e9e9e;
  border-top: 0;
  border-radius: 0 0 6px 6px;
}

.rollcount button {
  color: #676767;
  font-weight: bold;
  font-size: 1.2em;
  background: #fff;
}

.rollcount span {
  font-weight: bold;
  color: #212121;
  font-size: 1em;
}

.center button {
  margin: 10px 0 70px;
  font-size: 14px;
  width: 130px;
  height: 45px;
}

.center {
  text-align: center;
}

.rollcount > input[type="number"] {
  width: 45px;
  text-align: center;
  border: none;
}

.onlyNum {
  position: relative;
}

#maxCreditInput {
  background-color: transparent;
  color: transparent;
  position: relative;
  z-index: 1;
}
#maxCreditInput::selection {
  background: none;
}
#maxCreditInput + label {
  background: #fff;
  font-weight: 500;
  border: 1px solid #18698b;
  border-radius: 3px;
  height: 26px;
  padding: 0 6px;
  box-sizing: border-box;
  width: 100%;
  max-width: 190px;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.sub-title{
  padding:5px 10px;
}
.sub-title input[type=number]{
  width:80px;
}
</style>
